var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "col-12",
        staticStyle: { "padding-top": "30px !important" },
      },
      [
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfo,
            editable: _vm.editable && _vm.isWriting,
            label: "LBL0000302",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "col-12",
        staticStyle: { "padding-top": "30px !important" },
      },
      [
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfoJobPlan,
            editable: _vm.editable && _vm.isWriting,
            label: "LBL0000303",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "col-12",
        staticStyle: { "padding-top": "30px !important" },
      },
      [
        _c("c-upload", {
          attrs: {
            attachInfo: _vm.attachInfoEducationJournal,
            editable: _vm.editable && _vm.isWriting,
            label: "LBL0000304",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }